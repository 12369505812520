import Navbar from './components/Navbar';
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import Homepage from './Home';
import Productpage from './product/page';
import Contactpage from './contact/page';

function App() {
  return (
    <div>
        <BrowserRouter>
            <Navbar/>
            <Routes>
              <Route path='/' element={<Homepage/>}/>
              <Route path='/product' element={<Productpage/>}/>
              <Route path='/contact' element={<Contactpage/>}/>
            </Routes>

        </BrowserRouter>
    </div>
  );
}

export default App;
