import React from 'react';
import Carsoule1 from "../Assets/images/carsoule1_2.jpeg"; // Adjust the path as needed


function Carousel() {
    return (
        <article className="bg-[#4b6d00] text-white grid lg:grid-cols-[1fr_2fr] md:grid-cols-1 gap-8 lg:px-[10rem] lg:py-[2rem] md:px-[2rem] md:py-[1.5rem] sm:px-[1rem] sm:py-[1rem]">

            <section className="hero-text text-center lg:text-left">
                <h1 className="text-lg md:text-xl lg:text-3xl xl:text-4xl font-bold mt-0 text-[#c0b283] font-serif">
                    Kaya&apos;s
                </h1>

                <h2 className="text-2xl text-white lg:text-3xl mt-2">Digestive Care</h2>
                <p className="text-lg lg:text-xl mt-4 text-justify">
                    Start your day with a healthy warm beverage that includes apple cider vinegar, ginger, garlic, fresh lemon juice, and honey.
                </p>

                <br />
                <div className="bg-[#9A9F78] py-2 px-4 rounded-lg font-karla text-4xl text-center text-[#483C32] hover:bg-[#4b6d00] hover:text-[#eeeeee] hover:border hover:border-[#edefee] transition duration-200 inline-block cursor-pointer">
                    Detox Drink
                </div>


            </section>

            <section className="hero-image flex justify-center lg:justify-start mt-4 lg:mt-0">
                <img src={Carsoule1} alt="Little Lemon restaurant cuisine" className="max-w-full h-auto rounded-lg" />
            </section>
        </article>


    );
};

export default Carousel;
