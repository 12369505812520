import React from 'react';
import { Link } from 'react-router-dom';

import fbIcon from  '../Assets/images/fb.png';
import insta from '../Assets/images/insta.png';
import linkdn from '../Assets/images/linkdn.png';
import youtube from '../Assets/images/youtube.png';
import kayalogo from '../Assets/images/logo.png';
import loc from '../Assets/images/Sq_maps.png';
import ph from '../Assets/images/sq_ph.png';
import mail from '../Assets/images/sq_mail.png';
import sqwattsapp from '../Assets/images/sq_whatsapp.png';
import whatsapp from '../Assets/images/whatsapp.png';

const Footer = () => {
  return (
    <footer id="contact" className="bg-[#9A9F78] text-white ">
    <div className="max-w-screen-xl mx-auto px-4">
      <div className="flex flex-col md:flex-row justify-between">
        <div className="flex items-center bg-[#483C32] p-6 rounded-bl-lg w-full md:w-1/3 mb-4 md:mb-0">
          <a href="https://maps.app.goo.gl/mz3NhXKRsMeQxzyt7" target="_blank" rel="noopener noreferrer">
            <img src={loc} alt="geo" width={30} height={30} />
          </a>
          <div className="ml-4">
            <h5 className="font-bold text-lg">Coimbatore, Tamilnadu</h5>
          </div>
        </div>

        <div className="flex items-center bg-[#7A7D3A] p-6 w-full md:w-1/3 mb-4 md:mb-0">
          <div className="flex items-center">
            <img src={ph} alt="Phone No" width={30} height={30} className="mr-4" />
            <img src={sqwattsapp} alt="WhatsApp No" width={30} height={30} />
          </div>
          <div className="ml-4">
            <h5 className="font-bold text-lg">Contact Information</h5>
            <p>MOBILE: +91 9003031324</p>
          </div>
        </div>

        <div className="flex items-center bg-[#483C32] p-6 rounded-br-lg w-full md:w-1/3">
          <img src={mail} alt="email" width={30} height={30} />
          <div className="ml-4">
            <h5 className="font-bold text-lg">Email Address</h5>
            <p>priyadarshiniprakash78@gmail.com</p>
          </div>
        </div>
      </div>

      <div className="flex flex-col md:flex-row justify-between mt-8">
        <div className="w-full md:w-2/5">
          <img src={kayalogo} alt="Company Logo" width={100} height={100} />
          <p className="mt-4">
            <Link to="/" className="link-1">Home</Link> | 
            <Link to="/product">Product</Link> | 
            <Link to="/contact">Contact</Link>
          </p>
          <p className="mt-2">Kaya&apos;s © 2012</p>
        </div>

        <div className="w-full md:w-2/5 mx-auto text-left">
  <p>No 8 Kaveri Street</p>
  <p>Maharani Avenue, Vadavalli</p>
  <p>Coimbatore</p>
  <p>641041</p>
</div>

        <div className="w-full md:w-1/5">
          <p className="font-bold mb-2">About the company</p>
          <p className='text-justify mb-5'>Our mission is to empower individuals to lead healthier, happier lives by providing high-quality, natural products that promote overall well-being</p>

          <div className="flex space-x-4 mt-4">
            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
              <img src={fbIcon} alt="Facebook" width={30} height={30} />
            </a>
            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
              <img src={insta} alt="Instagram" width={30} height={30} />
            </a>
            <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
              <img src={linkdn} alt="LinkedIn" width={30} height={30} />
            </a>
            <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
              <img src={youtube} alt="YouTube" width={30} height={30} />
            </a>
            <a href="https://www.whatsapp.com" target="_blank" rel="noopener noreferrer">
              <img src={whatsapp} alt="WhatsApp" width={30} height={30} />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div className="text-center mt-8">
      <p>2020 © All Rights Reserved | Designed and Developed by Mauve Soft</p>
      <div className="mt-4 flex justify-center space-x-4">
        <i className="bi bi-citi"></i>
        <i className="bi bi-visa"></i>
        <i className="bi bi-worldpay"></i>
        <i className="bi bi-shopify"></i>
        <i className="bi bi-american-express"></i>
      </div>
    </div>
  </footer>
  )
}

export default Footer