import React from 'react';

const MapSection = () => {
  return (
    <section className="py-5 text-center bg-[#9A9F78]">
      <h2 className="text-2xl font-semibold">Find Us Here</h2>
      <div className="max-w-full mx-auto">
        <iframe
          title="Google Maps Location"
          src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3916.2428822851825!2d76.898149!3d11.020395!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTHCsDAxJzEzLjQiTiA3NsKwNTMnNTMuMyJF!5e0!3m2!1sen!2sin!4v1722865797159!5m2!1sen!2sin"
          width="100%"
          height="450"
          className="border-0 p-5"
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
    </section>
  );
};

export default MapSection;
