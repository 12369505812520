"use client"; // Mark this component as a Client Component

import React, { useState } from 'react';
import ProdMain from '../Assets/images/kaya_gmb/1.png';
import Prod1 from '../Assets/images/kaya_gmb/2.png';
import Prod2 from '../Assets/images/kaya_gmb/10.png';
import Prod3 from '../Assets/images/kaya_gmb/5.png';

const Prodcard = () => {
    const [mainImage, setMainImage] = useState(ProdMain); // State to hold the main image
    const [price, setPrice] = useState(1600); // State to hold the price
    const [selectedVolume, setSelectedVolume] = useState("1000ml"); // State to hold the selected volume

    const handleThumbnailClick = (image) => {
        setMainImage(image); // Update the main image when a thumbnail is clicked
    };

    const handleVolumeClick = (volume) => {
        setSelectedVolume(volume); // Update the selected volume

        // Update the price based on the selected volume
        switch (volume) {
            case "1000ml":
                setPrice(1600);
                break;
            case "500ml":
                setPrice(800);
                break;
            case "250ml":
                setPrice(400);
                break;
            default:
                setPrice(1600);
        }
    };

    return (
        <section className='flex flex-col md:flex-row justify-center items-center bg-[#d1d5c9] p-6 rounded-md'>
            {/* Image Section */}
            <div className='w-full md:w-[40%] pr-0 md:pr-28 mb-6 md:mb-0'>
                <div className='bg-gradient-to-b from-[#d1d5c9] to-[#d1d5c9] rounded-t-md'>
                    <div className="relative w-full h-[300px]">
                        <img
                            src={mainImage} // Display the currently selected main image
                            alt="Main product"
                            className="w-full h-full object-contain drop-shadow-lg saturate-150 contrast-125 hue-rotate-15"
                        />
                    </div>
                    <ul className="flex justify-around mt-4">
                        <li
                            className="w-16 h-16 md:w-14 md:h-14 p-2 border border-gray-400 rounded-md cursor-pointer"
                            onClick={() => handleThumbnailClick(ProdMain)} // Update image on click
                        >
                            <img
                                src={ProdMain}
                                alt="Product 1"
                                className="w-full h-auto object-contain"
                            />
                        </li>
                        <li
                            className="w-16 h-16 md:w-14 md:h-14 p-2 border border-gray-400 rounded-md cursor-pointer"
                            onClick={() => handleThumbnailClick(Prod1)} // Update image on click
                        >
                            <img
                                src={Prod1}
                                alt="Product 2"
                                className="w-full h-auto object-contain"
                            />
                        </li>
                        <li
                            className="w-16 h-16 md:w-14 md:h-14 p-2 border border-gray-400 rounded-md cursor-pointer"
                            onClick={() => handleThumbnailClick(Prod2)} // Update image on click
                        >
                            <img
                                src={Prod2}
                                alt="Product 3"
                                className="w-full h-auto object-contain"
                            />
                        </li>
                        <li
                            className="w-16 h-16 md:w-14 md:h-14 p-2 border border-gray-400 rounded-md cursor-pointer"
                            onClick={() => handleThumbnailClick(Prod3)} // Update image on click
                        >
                            <img
                                src={Prod3}
                                alt="Product 4"
                                className="w-full h-auto object-contain"
                            />
                        </li>
                    </ul>
                </div>
            </div>

            {/* Information Section */}
            <div className="w-full md:w-[30%] flex flex-col justify-center p-4 md:border-r-2 md:border-l-2 pl-0 md:pl-6">
                <h1 className="text-2xl font-bold text-gray-900">Digestive Care</h1>
                <span className="text-sm text-gray-600">COD: 45999</span>
                <div className="mt-4 text-[#4b6d00]">
                    <span className="text-xl">₹</span> <span className="text-3xl font-bold">{price}</span>
                </div>
                <div className="mt-4">
                    <h3 className="text-sm text-gray-700">SELECT Volume</h3>
                    <ul className="flex mt-2">
                        <li
                            className={`w-20 h-10 p-2 border border-gray-400 rounded-md cursor-pointer ${selectedVolume === "1000ml" ? "border-[#4b6d00]" : ""}`}
                            onClick={() => handleVolumeClick("1000ml")}
                        >
                            <h4>1000 ml</h4>
                        </li>
                        <li
                            className={`ml-2 w-20 h-10 p-2 border border-gray-400 rounded-md cursor-pointer ${selectedVolume === "500ml" ? "border-[#4b6d00]" : ""}`}
                            onClick={() => handleVolumeClick("500ml")}
                        >
                            <h4>500 ml</h4>
                        </li>
                        <li
                            className={`ml-2 w-20 h-10 p-2 border border-gray-400 rounded-md cursor-pointer ${selectedVolume === "250ml" ? "border-[#4b6d00]" : ""}`}
                            onClick={() => handleVolumeClick("250ml")}
                        >
                            <h4>250 ml</h4>
                        </li>
                    </ul>
                </div>
                {/*<button className="mt-6 px-6 py-3 bg-[#4b6d00] text-white rounded-lg hover:bg-[#003311] transition">
                    ADD TO CART
                </button>*/}
            </div>

            {/* Description Section */}
            <div className='w-full md:w-[30%] pl-0 md:pl-6'>
                <h2 className='text-lg font-bold'>Enhances cardiovascular health</h2>
                <p className='text-justify pb-4'>
                    Helps by breaking down plaque in the arteries, beneficial for heart health, improving blood flow, and reducing blood pressure.
                </p>

                <h2 className='text-lg font-bold'>Ease symptoms like indigestion, nausea, and bloating</h2>
                <p className='text-justify pb-4'>
                    The antioxidants in apple cider vinegar, honey, and ginger promote a healthy digestive system by reducing inflammation and supporting gut bacteria balance.
                </p>

                <h2 className='text-lg font-bold'>Helps with weight control</h2>
                <p className='text-justify pb-4'>
                    Helps by reducing body fat, boosting metabolism, and minimizing fluid retention. It supports a healthy metabolic rate and detoxifies the body.
                </p>
            </div>
        </section>
    );
};

export default Prodcard;
