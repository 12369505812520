import React from 'react';
import Digestjuice from "../Assets/images/digestive_box.jpeg";

const ProdDetails = () => {
  return (

    <article className="bg-[#d1d5c9] text-green-900 grid grid-cols-1 lg:grid-cols-4 p-5 lg:p-8 -mt-5">
      <section className="hero-image max-h-88 pl-8">
        <img src={Digestjuice} alt="Digestive Juice card" layout="responsive" objectFit="cover" className='rounded-lg'/>
      </section>

      <section className="testimonials lg:col-span-3">
        <div className="testimonials-cardtitle ml-8 lg:ml-10">
          <h1 className="text-3xl font-bold">Nutrition and Wellness</h1>
        </div>
        <div className="testimonials-cardinfo grid gap-7 lg:grid-cols-3 lg:ml-10 mb-10">
          <div className="testi-cont mt-2 max-w-full lg:max-w-11/12">
            <h4 className="testi-organic text-xl font-bold mb-0">Infusion Drink</h4>
            <p className="testi-text text-base text-justify">
              Our products are crafted with meticulous care, using only the finest, natural ingredients. We are passionate about bringing you the goodness of nature, straight to your doorstep.
            </p>
          </div>
          <div className="testi-cont mt-2 max-w-full lg:max-w-11/12">
            <h4 className="testi-organic text-xl font-bold mb-0">100% Pure and Natural Ingredients</h4>
            <p className="testi-text text-base text-justify">
              We prioritize natural and organic components, avoiding synthetic additives and preservatives, so you can trust that every sip is as wholesome as it is beneficial.
            </p>
          </div>
          <div className="testi-cont mt-2 max-w-full lg:max-w-11/12">
            <h4 className="testi-organic text-xl font-bold mb-0">High Quality</h4>
            <p className="testi-text text-base text-justify">
              Our commitment to high-quality ingredients not only benefits your health but also enhances the taste. Our digestive juice boasts a refreshing and pleasant flavor, making it easy to incorporate into your daily routine. Enjoy the beverage.
            </p>
          </div>
        </div>
      </section>
    </article>


  )
}

export default ProdDetails