"use client"; // Mark this component as a Client Component

import React, { useState } from 'react';

const Collapsible = () => {
  const [openSections, setOpenSections] = useState({});

  const toggleSection = (index) => {
    setOpenSections((prevOpenSections) => ({
      ...prevOpenSections,
      [index]: !prevOpenSections[index],
    }));
  };

  return (
    <div className="text-center mb-5 bg-gray-200 text-green-900 p-5">
      <h2 className="text-2xl mb-5">Frequently Asked Questions</h2>

      <div className="flex flex-col md:flex-row justify-between gap-5">
        {/* Left Column */}
        <div className="flex-1 max-w-full md:max-w-[48%]">
          <button
            className="bg-[#4b6d00] text-white cursor-pointer p-4 mx-auto w-full md:w-10/12 rounded-lg text-left outline-none text-sm relative hover:bg-[#003311] transition-colors duration-200"
            onClick={() => toggleSection(0)}
          >
            What are the antioxidant benefits of this drink?
            <span className="absolute right-5 text-lg">+</span>
          </button>
          <div
            className={`overflow-hidden transition-max-height duration-200 bg-gray-100 mb-5 mx-auto w-full md:w-10/12 ${
              openSections[0] ? 'max-h-52 p-4' : 'max-h-0'
            }`}
          >
            <p>
              The drink is abundant in antioxidants, which protect cells from the damaging effects of free radicals. This can reduce the risk of chronic diseases and improve overall health.
            </p>
          </div>

          <button
            className="bg-[#4b6d00] text-white cursor-pointer rounded-lg p-4 mx-auto w-full md:w-10/12 text-left outline-none text-sm relative hover:bg-[#003311] transition-colors duration-200"
            onClick={() => toggleSection(1)}
          >
            Is this drink beneficial for managing blood sugar levels?
            <span className="absolute right-5 text-lg">+</span>
          </button>
          <div
            className={`overflow-hidden transition-max-height duration-200 bg-gray-100 mb-5 mx-auto w-full md:w-10/12 ${
              openSections[1] ? 'max-h-52 p-4' : 'max-h-0'
            }`}
          >
            <p>
              Yes, the drink helps stabilize blood sugar levels, which is particularly beneficial for individuals managing diabetes. Apple cider vinegar, in particular, has been shown to improve insulin sensitivity and lower blood sugar levels.
            </p>
          </div>

          <button
            className="bg-[#4b6d00] text-white cursor-pointer rounded-lg p-4 mx-auto w-full md:w-10/12 text-left outline-none text-sm relative hover:bg-[#003311] transition-colors duration-200"
            onClick={() => toggleSection(2)}
          >
            What makes this drink good for gut health?
            <span className="absolute right-5 text-lg">+</span>
          </button>
          <div
            className={`overflow-hidden transition-max-height duration-200 bg-gray-100 mb-5 mx-auto w-full md:w-10/12 ${
              openSections[2] ? 'max-h-52 p-4' : 'max-h-0'
            }`}
          >
            <p>
              The drink is rich in beneficial bacteria, prebiotics, and fiber, which are essential for maintaining a healthy gut microbiome. These components help improve digestion and overall gut health.
            </p>
          </div>

          <button
            className="bg-[#4b6d00] text-white cursor-pointer rounded-lg p-4 mx-auto w-full md:w-10/12 text-left outline-none text-sm relative hover:bg-[#003311] transition-colors duration-200"
            onClick={() => toggleSection(3)}
          >
            Can I prepare this drink at home?
            <span className="absolute right-5 text-lg">+</span>
          </button>
          <div
            className={`overflow-hidden transition-max-height duration-200 bg-gray-100 mb-5 mx-auto w-full md:w-10/12 ${
              openSections[3] ? 'max-h-52 p-4' : 'max-h-0'
            }`}
          >
            <p>
              Yes, you can easily prepare this drink at home by mixing apple cider vinegar, honey, and ginger. Optionally, you can add garlic for additional health benefits. Ensure to use high-quality, organic ingredients for the best results.
            </p>
          </div>
        </div>

        {/* Right Column */}
        <div className="flex-1 max-w-full md:max-w-[48%]">
          <button
            className="bg-[#4b6d00] text-white cursor-pointer rounded-lg p-4 mx-auto w-full md:w-10/12 text-left outline-none text-sm relative hover:bg-[#003311] transition-colors duration-200"
            onClick={() => toggleSection(5)}
          >
            Does this drink have antimicrobial properties?
            <span className="absolute right-5 text-lg">+</span>
          </button>
          <div
            className={`overflow-hidden transition-max-height duration-200 bg-gray-100 mb-5 mx-auto w-full md:w-10/12 ${
              openSections[5] ? 'max-h-52 p-4' : 'max-h-0'
            }`}
          >
            <p>
              Yes, the ingredients in this drink have strong antimicrobial properties, which can help prevent infections and boost the immune system.
            </p>
          </div>

          <button
            className="bg-[#4b6d00] text-white cursor-pointer rounded-lg p-4 mx-auto w-full md:w-10/12 text-left outline-none text-sm relative hover:bg-[#003311] transition-colors duration-200"
            onClick={() => toggleSection(6)}
          >
            How often should I consume this drink for optimal benefits?
            <span className="absolute right-5 text-lg">+</span>
          </button>
          <div
            className={`overflow-hidden transition-max-height duration-200 bg-gray-100 mb-5 mx-auto w-full md:w-10/12 ${
              openSections[6] ? 'max-h-52 p-4' : 'max-h-0'
            }`}
          >
            <p>
              It is recommended to consume this drink daily. However, it's important to consult with a healthcare provider to determine the best regimen for your specific health needs.
            </p>
          </div>

          <button
            className="bg-[#4b6d00] text-white cursor-pointer rounded-lg p-4 mx-auto w-full md:w-10/12 text-left outline-none text-sm relative hover:bg-[#003311] transition-colors duration-200"
            onClick={() => toggleSection(7)}
          >
            Are there any potential side effects?
            <span className="absolute right-5 text-lg">+</span>
          </button>
          <div
            className={`overflow-hidden transition-max-height duration-200 bg-gray-100 mb-5 mx-auto w-full md:w-10/12 ${
              openSections[7] ? 'max-h-52 p-4' : 'max-h-0'
            }`}
          >
            <p>
              While the drink is generally safe for most individuals, it’s important to consume it in moderation. Consult your healthcare provider before adding this drink to your routine, especially if you have any pre-existing health conditions or are taking medications.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Collapsible;
