import React from 'react'
import ContactHeading from './components/contactHeading'
//import ContactSend from './components/contactform'
import MapSection from './components/mapSection'
import Footer from '../components/Footer'

const Contactpage = () => {
  return (
    <div>
      <ContactHeading />
      <MapSection />
      <Footer />
    </div>
  )
}

export default Contactpage