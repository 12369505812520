import React from 'react'
import { Digest } from './components/digestive'
import Footer from '../components/Footer'

const Productpage = () => {
  return (
    <div className="space-y-0">
        <Digest />
        <Footer />
    </div>
  )
}

export default Productpage