import React from 'react';
import Carousel from "./home_comp/carousel.jsx";
import ProdCard from "./home_comp/Prodcard.jsx";
import ProdDetails from './home_comp/ProdDetails.jsx';
import ProdQA from './home_comp/ProdQA.jsx';
import Footer from './components/Footer.jsx';

function Homepage () {
  return (
    <div className="space-y-0">
      <Carousel />
      <ProdDetails />
      <ProdCard />
      <ProdQA />
      <Footer />
      
    </div>
  )
}

export default Homepage;