import hangingph from '../../Assets/images/hanging-ph-flat-text.png';

export default function ContactHeading() {
  const handleClick = () => {
    window.open('https://forms.gle/ethuqLoBHm7nZ9zZ7', '_blank');
  };
  return (
    <header>
      <article className="bg-[#4b6d00] py-4">
        <section className="flex justify-center">
          <img 
            src={hangingph} 
            alt="Hanging Phone" 
            className="w-[80%] md:w-[40%] lg:w-[30%] h-auto" 
            priority={true}
          />
        </section>
        <center>
        <button
        onClick={handleClick}
        className="px-6 py-3 mt-14 bg-red-500 text-white rounded-lg text-lg font-medium hover:bg-[#4b6d00] hover:text-[#eeeeee] hover:border hover:border-[#edefee] transition duration-200"
      >
        Click here to contact Kaya's
      </button>
      </center>
      </article>
    </header>
  );
}
