import React from 'react';
//import arrowIcon from '../../Assets/images/arrow.png';
import digest from '../../Assets/images/digest prod image.png';

export const Digest = () => {
  return (
    <div className="h-screen bg-gradient-to-b from-[#4b6d00] to-[#e1ffea22] mb-9 flex flex-col lg:flex-row">
      {/* Left Section */}
      <div className="flex-1 flex flex-col justify-center gap-0 p-5 md:pl-20 lg:pl-36 leading-tight text-center lg:text-left">
        <h1 className="text-[#171717] text-[40px] md:text-[50px] lg:text-[70px] font-bold">Our</h1>
        <h1 className="text-[#171717] text-[40px] md:text-[50px] lg:text-[70px] font-bold">
          Digestive Care
        </h1>
        <p className="text-[#171717] text-[30px] md:text-[40px] lg:text-[50px] font-bold mb-5">
          for everyone
        </p>
        <h2 className="text-[#090909] text-[16px] md:text-[20px] lg:text-[26px] font-semibold">
          Improves Immunity & Digestion
        </h2>
        {/*<div className="flex justify-between items-center gap-2 lg:gap-4 w-[180px] md:w-[250px] lg:w-[330px] h-[40px] md:h-[50px] lg:h-[70px] rounded-[75px] mt-5 md:mt-7 bg-[#ff4141] text-white text-[12px] md:text-[16px] lg:text-[22px] font-medium pl-5 pr-3 whitespace-nowrap">
          <div>
            Call us to book your Order
          </div>
          <img src={arrowIcon} alt="" width={20} height={20} className="ml-auto" />
        </div>*/}
      </div>
      {/* Right Section */}
      <div className="flex-1 flex items-center justify-center lg:justify-start">
        <img src={digest} alt="Digestive Care" className="w-[50%] md:w-[60%] lg:w-[70%] ml-0 lg:ml-[14%]" />
      </div>
    </div>
  );
};
