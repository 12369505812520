"use client"; // Ensures this component is treated as a Client Component

import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from "../Assets/images/logo.png";
import hamburgerIcon from "../Assets/images/hamburger_icon.png";

const Navbar = () => {
    const location = useLocation(); // Get the current route
    const [menu, setMenu] = useState("");
const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    useEffect(() => {
        // Set the active menu item based on the current path
        if (location.pathname === "/") {
            setMenu("home");
        } else if (location.pathname === "/product") {
            setMenu("product");
        } else if (location.pathname === "/contact") {
            setMenu("contact");
        } else {
            setMenu(""); // In case you have other routes
        }
    }, [location.pathname]); // Run this effect whenever the path changes

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    return (
        <nav className="flex justify-between items-center bg-[#9A9F78] shadow-md opacity-100">
            {/* Left Side: Logo and Navigation links for larger screens */}
            <div className="flex items-center space-x-10">
                {/* Logo */}
                <div className="ml-2">
                    <img className="w-28 h-auto" src={logo} alt="Kaya's Logo" />
                </div>

                {/* Navigation links for larger screens */}
                <ul className="hidden md:flex items-center space-x-8 text-[#626262] text-xl font-medium">
                    <li onClick={() => setMenu("home")} className={`relative ${menu === "home" ? "text-green-900" : "text-black"}`}>
                        <Link to="/" className="text-xl">Home</Link>
                        {menu === "home" && <hr className="absolute bottom-0 left-0 w-4/5 border-t-2 border-red-500 rounded-lg" />}
                    </li>
                    <li onClick={() => setMenu("product")} className={`relative ${menu === "product" ? "text-green-900" : "text-black"}`}>
                        <Link to="/product" className="text-xl">Product</Link>
                        {menu === "product" && <hr className="absolute bottom-0 left-0 w-4/5 border-t-2 border-red-500 rounded-lg" />}
                    </li>
                    <li onClick={() => setMenu("contact")} className={`relative ${menu === "contact" ? "text-green-900" : "text-black"}`}>
                        <Link to="/contact" className="text-xl">Contact</Link>
                        {menu === "contact" && <hr className="absolute bottom-0 left-0 w-4/5 border-t-2 border-red-500 rounded-lg" />}
                    </li>
                </ul>
            </div>

            {/* Right Side: Hamburger Icon for Mobile */}
            <div className="md:hidden cursor-pointer mr-4" onClick={toggleMobileMenu}>
                <img src={hamburgerIcon} alt="Menu" width={24} height={24} />
            </div>

            {/* Mobile Menu */}
            <div className={`md:hidden ${isMobileMenuOpen ? 'block' : 'hidden'} mt-4`}>
                <ul className="flex flex-col items-center space-y-4">
                    <li onClick={() => setMenu("home")} className={`relative ${menu === "home" ? "text-green-900" : "text-black"}`}>
                        <Link to="/" className="text-xl">Home</Link>
                        {menu === "home" && <hr className="absolute bottom-0 left-0 w-4/5 border-t-2 border-red-500 rounded-lg" />}
                    </li>
                    <li onClick={() => setMenu("product")} className={`relative ${menu === "product" ? "text-green-900" : "text-black"}`}>
                        <Link to="/product" className="text-xl">Product</Link>
                        {menu === "product" && <hr className="absolute bottom-0 left-0 w-4/5 border-t-2 border-red-500 rounded-lg" />}
                    </li>
                    <li onClick={() => setMenu("contact")} className={`relative ${menu === "contact" ? "text-green-900" : "text-black"}`}>
                        <Link to="/contact" className="text-xl">Contact</Link>
                        {menu === "contact" && <hr className="absolute bottom-0 left-0 w-4/5 border-t-2 border-red-500 rounded-lg" />}
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default Navbar;
